<template>
  <a-layout class='index animated fadeIn'>
    <a-card>
      <a-form ref='form' :model='search' :style="{'marginBottom':'20px'}" :initialValues="search" :onFinish='pageSearchChange' layout='inline'>
        <a-form-item label="操作用户" name='level'>
          <a-select v-model:value="search.admin_id" :style="{width:'180px'}" placeholder="全部">
             <a-select-option value="" >
              全部
            </a-select-option>
            <a-select-option :value="v.id" v-for="(v,k ) in admins" :key='k'>
              {{v.username}}
            </a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="操作时间" >
          <a-range-picker v-model:value="timer"  @change="onChange" valueFormat='YYYY-MM-DD' />
        </a-form-item>
        <a-form-item>
          <a-button type="primary" htmlType='submit'>搜索</a-button>
        </a-form-item>
        <a-form-item>
          <a-button type="default" @click='pageSearchReset'>重置</a-button>
        </a-form-item>
      </a-form>
      <a-table :pagination='false' :loading='listLoading' bordered rowKey='id' :columns='columns' :data-source='data'>
      </a-table>
      <a-pagination :style="{marginTop:'20px',textAlign:'right'}" :current="search.page" :page-size="search.limit" :total="total" show-size-changer show-quick-jumper @change="pageCurrentChange" @showSizeChange="pageSizeChange"  :show-total="total => `总数: ${total} `" />
    </a-card>
  </a-layout>
</template>
<script>
import { ref, onMounted, inject } from "vue";
import { $iscode } from "@/utils/app";
import  {getLogs,getAdmin}  from '@/api/system'
// 初始化默认筛选项数值
let defSearch = {
  admin_id: "",
  keywords: '',
  page: 1,
  limit: 10,
  start_time: '',
  end_time:""
};
export default {
  setup(props) {
    // // 初始化 分页信息和筛选项信息
    let total = ref(1);
    let search = ref({ ...defSearch });
    // 列表数据和列头配置
    let data = ref();
    let listLoading = ref(false);
    let columns = [
      {
        title: "操作用户",
        dataIndex: "admin_name",
        key: "admin_name",
      },
      {
        title: "接口地址",
        dataIndex: "admin_path",
        key: "admin_path",
      },
       {
        title: "接口方法",
        dataIndex: "method",
        key: "method",
      },
      {
        title: "IP",
        dataIndex: "ip",
        key: "ip",
      },
      {
        title: "操作时间",
        dataIndex: "update_time",
        key: "update_time",
      },
      {
        title: "操作内容",
        dataIndex: "desc",
        key: "desc",
      },
    ];
    // 获取 管理员列表 
    const admins=ref([])
    const getAdmins=async()=>{
      let result=await getAdmin().then(res=>res.data).catch(error=>error)
      admins.value=result.data.data
    }
   
    // 页面筛选项搜索
    const pageSearchChange = () => {
      search.value = { ...search.value, page: 1 };
      initData(search.value);
    };
    const timer=ref([])
    // 页面筛选项重置
    const pageSearchReset = () => {
      search.value = {
        ...defSearch,
        page: search.value.page,
        limit: search.value.limit,
      };
      timer.value=[]
    };
    // 分页当前页切换
    const pageCurrentChange = (page, pageSize) => {
      search.value.page = page;
      initData(search.value);
    };
    // 切换时间
    const onChange = (e) => {
      search.value.start_time=e[0]
      search.value.end_time=e[1]
    };
    // 分页当前页显示多少条切换
    const pageSizeChange = (current, limit) => {
      search.value.page = 1;
      search.value.limit = limit;
      initData(search.value);
    };
    const initData = async (values) => {
      listLoading.value = true;
      try {
        let res = await getLogs(values).then(res=>res.data).catch(error=>error);
        listLoading.value = false;
 
        if ($iscode(res)) {
          data.value = res.data.data;
          total.value = res.data.total;
        } else {
          inject("$message").error(res.message);
        }
      } catch (e) {
        listLoading.value = false;
      }
    };
    const action = (e) => {
      console.log(e);
    };
    onMounted(() => {
      initData(search.value);
       getAdmins()
    });
    return {
      total,
      search,
      data,
      listLoading,
      columns,
      pageSearchChange,
      pageSearchReset,
      pageCurrentChange,
      pageSizeChange,
      action,
      onChange,
      admins,
      timer
    };
  },
};
</script>